define("discourse/plugins/discourse-authorship/discourse/initializers/authorship-initializer", ["exports", "discourse/models/composer", "discourse/plugins/discourse-authorship/discourse/lib/utilities", "discourse-common/utils/decorators", "discourse/lib/plugin-api", "virtual-dom", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _composer, _utilities, _decorators, _pluginApi, _virtualDom, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: 'authorship-initializer',
    initialize(container) {
      const currentUser = container.lookup('current-user:main');
      _composer.default.serializeOnCreate('user_id', 'user.id');
      (0, _pluginApi.withPluginApi)('0.8.37', api => {
        var _dec, _obj, _dec2, _obj2;
        api.reopenWidget('post-menu', {
          defaultState(attrs) {
            let state = this._super();
            state['showLikeUserMenu'] = false;
            state['likeMenuEnabled'] = currentUser && currentUser.author_selection_enabled;
            return state;
          },
          toggleLikeUserMenu(forceClose) {
            this.state.showLikeUserMenu = forceClose ? false : !this.state.showLikeUserMenu;
          },
          attachLikeButton(name) {
            const {
              attrs,
              settings
            } = this;
            let contents = [this.attach(settings.buttonType, (0, _utilities.likeButtonAttrs)(attrs))];
            if (attrs.likeCount > 0) {
              contents.unshift(this.attach(settings.buttonType, (0, _utilities.likeCountButtonAttrs)(attrs)));
            }
            return (0, _virtualDom.h)("div.double-button", contents);
          },
          attachButton(name) {
            if (this.state.likeMenuEnabled && name === 'like') {
              return this.attachLikeButton(name);
            } else {
              return this._super(name);
            }
          },
          like() {
            const {
              attrs,
              state
            } = this;
            if (state.likeMenuEnabled) {
              if (state.likedUsers.length) {
                state.likedUsers = [];
                this.scheduleRerender();
              } else {
                return this.toggleLikeUserMenu();
              }
            } else {
              return this._super();
            }
          },
          html(attrs, state) {
            let contents = this._super(attrs, state);
            if (state.showLikeUserMenu) {
              attrs.likedUsers = state.likedUsers;
              contents.forEach(w => {
                if (w.properties && w.properties.className.indexOf('post-controls') > -1) {
                  w.children.forEach(w => {
                    if (w.properties && w.properties.className.indexOf("actions") > -1 && !$.grep(w.children, w => w.key === `like-user-menu-${attrs.id}`).length) {
                      w.children.push(this.attach('like-user-menu', attrs));
                    }
                  });
                }
              });
            }
            return contents;
          }
        });
        api.reopenWidget('post', {
          likeByUser(opts) {
            const post = this.model;
            const likeAction = post.get("likeAction");
            post.set('like_username', opts.username);
            post.set('like_action', opts.action);
            if (likeAction) {
              return likeAction.togglePromise(post).then(result => {
                this.appEvents.trigger("page:like-by-user-complete", post, likeAction);
                return this._warnIfClose(result);
              });
            }
          }
        });
        api.includePostAttributes('topic', 'actions_summary');
        api.addPostTransformCallback(p => {
          if (p.topic && currentUser && currentUser.author_selection_enabled) {
            p.showLike = true;
            p.canToggleLike = true;
          }
        });
        api.modifyClass('model:action-summary', {
          togglePromise(post) {
            if (post.like_action) {
              return post.like_action === 'act' ? this.act(post) : this.undo(post);
            } else {
              return this._super(post);
            }
          },
          act(post, opts) {
            if (!opts) opts = {};
            if (post.like_username) {
              this.setProperties({
                count: this.count + 1,
                can_undo: true
              });
              return (0, _ajax.ajax)("/post_actions", {
                type: "POST",
                data: {
                  id: this.flagTopic ? this.get("flagTopic.id") : post.get("id"),
                  post_action_type_id: this.id,
                  message: opts.message,
                  is_warning: opts.isWarning,
                  take_action: opts.takeAction,
                  flag_topic: this.flagTopic ? true : false,
                  like_username: post.like_username
                },
                returnXHR: true
              }).then(data => {
                if (!this.flagTopic) {
                  post.updateActionsSummary(data.result);
                }
                return {};
              }).catch(error => {
                (0, _ajaxError.popupAjaxError)(error);
                this.removeAction(post);
              });
            } else {
              return this._super(post, opts);
            }
          },
          undo(post) {
            if (post.like_username) {
              this.removeAction(post);
              return (0, _ajax.ajax)("/post_actions/" + post.get("id"), {
                type: "DELETE",
                data: {
                  post_action_type_id: this.id,
                  like_username: post.like_username
                }
              }).then(result => {
                post.updateActionsSummary(result);
                return {};
              });
            } else {
              return this._super(post);
            }
          }
        });
        api.modifyClass('component:composer-presence-display', (_dec = (0, _decorators.on)("didInsertElement"), (_obj = {
          userChanged() {
            this.appEvents.on("author-selector:author-updated", user => {
              this.updateState(user);
            });
          },
          updateState(user) {
            let state = null;
            const action = this.action;
            const currentUser = this.currentUser;
            if (action === "reply" || action === "edit") {
              state = {
                action
              };
              if (action === "reply") state.topic_id = this.get("topic.id");
              if (action === "edit") state.post_id = this.get("post.id");
              if (currentUser.author_selection_enabled) {
                if (!user) return;
                state.user_id = user.id;
              }
            }
            this.set("previousState", this.currentState);
            this.set("currentState", state);
          }
        }, (_applyDecoratedDescriptor(_obj, "userChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "userChanged"), _obj)), _obj)));
        api.modifyClass('controller:preferences/interface', (_dec2 = (0, _decorators.default)('makeThemeDefault'), (_obj2 = {
          saveAttrNames() {
            const attrs = this._super(...arguments);
            if (!attrs.includes("custom_fields")) attrs.push("custom_fields");
            return attrs;
          }
        }, (_applyDecoratedDescriptor(_obj2, "saveAttrNames", [_dec2], Object.getOwnPropertyDescriptor(_obj2, "saveAttrNames"), _obj2)), _obj2)));
      });
    }
  };
});
define("discourse/plugins/discourse-authorship/discourse/templates/connectors/user-preferences-interface/author-selection-user-controls", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if model.author_selection_enabled}}
    <div class="control-group author-selection">
      <label class="control-label">
        {{i18n "user_preferences.author_selection"}}
      </label>
      <div class="controls">
        {{user-selector 
          id="author-list"
          placeholder=(i18n "user_preferences.author_selection_list")
          tabindex="1"
          usernames=model.custom_fields.author_list
          autocomplete="discourse"}}
      </div>
    </div>
  {{/if}}
  
  */
  {
    "id": "t4jH4JDj",
    "block": "[[[41,[30,0,[\"model\",\"author_selection_enabled\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group author-selection\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"user_preferences.author_selection\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"id\",\"placeholder\",\"tabindex\",\"usernames\",\"autocomplete\"],[\"author-list\",[28,[37,1],[\"user_preferences.author_selection_list\"],null],\"1\",[30,0,[\"model\",\"custom_fields\",\"author_list\"]],\"discourse\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-authorship/discourse/templates/connectors/user-preferences-interface/author-selection-user-controls.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-authorship/discourse/templates/connectors/user-preferences-interface/author-selection-user-controls.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"i18n\",\"user-selector\"]]",
    "moduleName": "discourse/plugins/discourse-authorship/discourse/templates/connectors/user-preferences-interface/author-selection-user-controls.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-authorship/discourse/components/author-selector", ["exports", "discourse/models/user", "discourse-common/utils/decorators", "@ember/component"], function (_exports, _user, _decorators, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)('currentUser.author_list'), (_obj = {
    classNames: 'author-selector',
    authorList(userAuthorList) {
      return (userAuthorList || '').split(',').map(username => {
        return {
          id: username,
          name: username
        };
      });
    },
    actions: {
      updateAuthor(authorUsername) {
        if (authorUsername !== this.composer.user.username) {
          this.set('updatingAuthor', true);
          _user.default.findByUsername(authorUsername).then(user => {
            this.set('composer.user', user);
            this.appEvents.trigger("author-selector:author-updated", user);
          }).finally(() => {
            this.set('updatingAuthor', false);
          });
        } else {
          this.appEvents.trigger("author-selector:author-updated", this.composer.user);
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "authorList", [_dec], Object.getOwnPropertyDescriptor(_obj, "authorList"), _obj)), _obj)));
});